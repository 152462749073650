/* eslint-disable @next/next/no-img-element */
import Head from "next/head";
import Link from "next/link";
import { Avatar } from "./Avatar";
import { Spinner } from "./Spinner";
import { useState } from "react";
import { DarkModeToggle } from "./DarkModeToggle";
import Script from "next/script";

export default function Header(props) {
    let { title, description, user, hideAvatar, hideLogin, highlightCreateButton } = props;
    const [createLoading, setCreateLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    title = title
        ? title
        : "VectorArt.ai - Generate vector images with AI";
    description =
        description ||
        "The largest AI generated vector image library and AI vector art generator. Browse and download unlimited SVG vector images, illustrations and clipart for free or create your own with AI.";

    return (
        <div
            className={`container group mx-auto sm:px-6 lg:px-8 py-6 sm:py-0 ${
                props.className?.includes("secondary") ? "" : "mb-8 sm:mb-0"
            } ${props.className}`}
        >
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                {props.image ? (
                    <meta property="og:image" content={props.image} />
                ) : (
                    <meta
                        property="og:image"
                        content="https://vectorart.ai/og512.png"
                    />
                )}
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                {/* <meta name="twitter:image" content={props.image?.replace('https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/', 'https://vectorart.ai/preview/') + '.jpg' || "https://vectorart.ai/logo.png"} /> */}

                <link rel="icon" href="/favicon.ico" />
                {props.permalink && (
                    <link rel="canonical" href={props.permalink} />
                )}
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;300;400;500;700&display=swap"
                    rel="stylesheet"
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `
                    {
                        "@context": "http://schema.org/",
                        "@type": "Product",
                        "name": [
                            "VectorArt.ai",
                            "VectorArt",
                            "Vector Art AI"
                        ],
                        "image": [
                            "https://vectorart.ai/og512.png",
                            "https://vectorart.ai/og.png"
                        ],
                        "description": "The largest AI generated vector image library and AI vector art generator. Browse and download unlimited images for free or create your own with AI. SVGs and PNGs available for download. Prompt our specialized AI to generate a new unique image for your needs.",
                        "brand": {
                            "@type": "Thing",
                            "name": [
                                "VectorArt.ai",
                                "VectorArt",
                                "Vector Art AI"
                            ]
                        },
                        "offers": {
                            "@type": "Offer",
                            "price": "0",
                            "priceCurrency": "USD",
                            "availability": "http://schema.org/InStock",
                            "url": "https://vectorart.ai",
                            "seller": {
                                "@type": "Organization",
                                "name": "VectorArt.ai"
                            }
                        }
                    }
                `,
                    }}
                />
            </Head>

            <Script
                defer
                src="https://what.vectorart.ai/script.js"
                data-website-id="2f74c630-8b47-4a90-9287-0051fe24f60a"
            />
            <div
                className={`header flex flex-wrap items-center md:justify-between dark:text-gray-100${
                    props.slim ? "py-2" : "py-5"
                } sm:py-5`}
            >
                <div className="ml-4 min-w-0 flex-1 cursor-pointer">
                    <div className="flex items-center">
                        {/* <div>
                                <img
                                    alt="VectorArt.ai"
                                    src={"/robocolo.png"}
                                    className="w-9 mr-3 -mt-1"
                                />
                            </div> */}
                        <Link href="/">
                            <a>
                                <h2
                                    style={{
                                        fontFamily: "'Ubuntu', sans-serif",
                                    }}
                                    className={`${
                                        props.slim ? "text-sm" : "text-2xl"
                                    } font-medium leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight text-center logo inline`}
                                >
                                    <span className="text-white">
                                        VectorArt.
                                    </span>
                                    <span className="text-secondary">ai</span>
                                </h2>
                            </a>
                        </Link>
                    </div>
                </div>
                <div
                    className={
                        "mr-4 hidden sm:flex items-center text-white group-[.secondary]:text-black dark:text-white dark:group-[.secondary]:text-white font-medium text-sm " +
                        (props.main ? " my-header-links" : "")
                    }
                >
                    <div className="hover:opacity-70 mr-6 last:mr-2">
                        <Link href="/browse">Browse</Link>
                    </div>
                    {props.showPricing !== false && (
                        <div className="hover:opacity-70 mr-6 last:mr-2">
                            <Link href="/pricing">Pricing</Link>
                        </div>
                    )}
                    {/* <div className={"hover:opacity-80 mr-6  last:mr-2"}>
                        <Link href="/get-started/api">API</Link>
                    </div> */}
                    {(!user || hideAvatar) && !hideLogin && (
                        <div className="hover:opacity-70 mr-6  last:mr-2">
                            <Link href="/login">Sign in</Link>
                        </div>
                    )}
                </div>
                <div
                    className={`${
                        props.slim ? "hidden" : ""
                    } mr-4 items-center hidden sm:flex`}
                >
                    {props.showCreate !== false && (
                        <div className="">
                            <form>
                                <Link href="/create">
                                    <button
                                        onClick={() => setCreateLoading(true)}
                                        type="button"
                                        className={`flex items-center inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium focus:outline-none sm:col-start-2 sm:text-sm 
                                            ${
                                                highlightCreateButton
                                                    ? "from-primary-dark border-0 to-secondary-dark bg-gradient-to-br text-white hover:bg-blue-700"
                                                    : "bg-white dark:bg-slate-900 text-black dark:text-white border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-slate-800"
                                            }`}
                                    >
                                        {createLoading ? (
                                            <div className="-ml-1 -mt-1 -mb-1 mr-1 w-6 h-6">
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <span className="text-lg mr-2 sparkle w-4">
                                                <img
                                                    width="16"
                                                    height="16"
                                                    alt="Create"
                                                    src={
                                                        highlightCreateButton
                                                            ? "/sparkle-white.svg"
                                                            : "/sparkle.svg"
                                                    }
                                                />
                                            </span>
                                        )}
                                        <div>
                                            {highlightCreateButton
                                                ? "Create image with AI"
                                                : "Create"}
                                        </div>
                                    </button>
                                </Link>
                            </form>
                        </div>
                    )}
                    {props.showCreateLink && (
                        <div className="font-medium text-sm hover:opacity-80 mr-6 last:mr-2 text-white">
                            <Link href="/create">Create</Link>
                        </div>
                    )}
                    {user && !hideAvatar && (
                        <div className="ml-4 md:ml-6 mr-4 mt-1">
                            <Avatar
                                opacity={
                                    props.className?.includes("secondary")
                                        ? 1
                                        : 0.5
                                }
                                user={user}
                                supabase={props.supabase}
                            />
                        </div>
                    )}
                    <DarkModeToggle className="ml-16 right-0" />
                </div>
                <div className="sm:hidden mr-4 -mb-1">
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="text-white dark:text-gray-300 group-[.secondary]:text-black dark:group-[.secondary]:text-gray-300 focus:outline-none"
                    >
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            {isMenuOpen && (
                <div className="sm:hidden bg-primary group-[.secondary]:bg-white text-white group-[.secondary]:text-black py-2 mt-8 mb-6 dark:bg-gray-900 dark:group-[.secondary]:bg-gray-900 dark:text-gray-400 dark:group-[.secondary]:text-gray-300">
                    <div className="px-4 pt-2 pb-3 space-y-1">
                        <Link href="/browse">
                            <a className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white">
                                Browse
                            </a>
                        </Link>
                        {props.showPricing !== false && (
                            <Link href="/pricing">
                                <a className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white">
                                    Pricing
                                </a>
                            </Link>
                        )}
                        {(!user || hideAvatar) && !hideLogin && (
                            <Link href="/login">
                                <a className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white">
                                    Sign in
                                </a>
                            </Link>
                        )}
                        {props.showCreate !== false && (
                            <Link href="/create">
                                <a className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white dark:roup">
                                    Create
                                    <span className="text-lg ml-2 sparkle w-4 inline-flex align-text-bottom dark:hidden">
                                        <img
                                            width="16"
                                            height="16"
                                            alt="Create"
                                            src="/sparkle.svg"
                                        />
                                    </span>
                                    <span className="text-lg ml-2 sparkle w-4 align-text-bottom hidden dark:group-hover:inline-flex">
                                        <img
                                            width="16"
                                            height="16"
                                            alt="Create"
                                            src="/sparkle-white.svg"
                                        />
                                    </span>
                                    <span className="text-lg ml-2 sparkle w-4 align-text-bottom hidden dark:inline-flex dark:group-hover:hidden">
                                        <img
                                            width="16"
                                            height="16"
                                            alt="Create"
                                            src="/sparkle.svg"
                                        />
                                    </span>
                                </a>
                            </Link>
                        )}
                        {user && !hideAvatar && (
                            <Link href="/account">
                                <a className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white">
                                    Account
                                </a>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
