import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

export function DarkModeToggle(props) {
    const { setTheme } = useTheme();
    return (
        <div className={props.className}>
            <button
                type="button"
                className="hs-dark-mode-active:hidden group flex dark:hidden items-center text-gray-300 hover:text-gray-400 font-medium dark:text-neutral-400 dark:hover:text-white"
                onClick={() => {
                    setTheme("dark");
                    window.umami?.track("Dark Mode Toggle - Dark mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
                </svg>
            </button>
            <button
                type="button"
                className="hs-dark-mode-active:block hidden group dark:flex items-center text-gray-600 hover:text-black font-medium dark:text-neutral-400 dark:hover:text-white"
                onClick={() => {
                    setTheme("light");
                    window.umami?.track("Dark Mode Toggle - Light mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="4"></circle>
                    <path d="M12 2v2"></path>
                    <path d="M12 20v2"></path>
                    <path d="m4.93 4.93 1.41 1.41"></path>
                    <path d="m17.66 17.66 1.41 1.41"></path>
                    <path d="M2 12h2"></path>
                    <path d="M20 12h2"></path>
                    <path d="m6.34 17.66-1.41 1.41"></path>
                    <path d="m19.07 4.93-1.41 1.41"></path>
                </svg>
            </button>
        </div>
    );
}


export function DarkModeToggleFull(props) {
    const { theme, setTheme } = useTheme();
    const [lightClass, setLightClass] = useState("");
    const [darkClass, setDarkClass] = useState("");
    const [systemClass, setSystemClass] = useState("");

    useEffect(() => {
        setLightClass(`${baseClass} ${
            theme === "light" ? selectedClass : unselectedClass
        }`);
        setDarkClass(`${baseClass} ${
            theme === "dark" ? selectedClass : unselectedClass
        }`);
        setSystemClass(`${baseClass} ${
            "system" === theme ? selectedClass : unselectedClass
        }`);
    }, [theme]);

    const baseClass = "group flex items-center font-medium p-2";
        const selectedClass = "text-secondary"; // Update with your selected state class
    const unselectedClass =
        "text-gray-600 hover:text-black dark:text-neutral-400 dark:hover:text-white";

    console.log(theme, "system" === theme, theme === "system" ? selectedClass : unselectedClass);
    return (
        <div className={`flex ${props.className}`}>
            {/* Light Mode Button */}
            <button
                type="button"
                className={lightClass}
                onClick={() => {
                    setTheme("light");
                    window.umami?.track("Dark Mode Toggle - Light mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="5"></circle>
                    <path d="M12 1v2"></path>
                    <path d="M12 21v2"></path>
                    <path d="M4.22 4.22l1.42 1.42"></path>
                    <path d="M18.36 18.36l1.42 1.42"></path>
                    <path d="M1 12h2"></path>
                    <path d="M21 12h2"></path>
                    <path d="M4.22 19.78l1.42-1.42"></path>
                    <path d="M18.36 5.64l1.42-1.42"></path>
                </svg>
            </button>

            {/* Dark Mode Button */}
            <button
                type="button"
                className={darkClass}
                onClick={() => {
                    setTheme("dark");
                    window.umami?.track("Dark Mode Toggle - Dark mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>
            </button>

            {/* System Mode Button */}
            <button
                type="button"
                className={systemClass}
                onClick={() => {
                    setTheme("system");
                    window.umami?.track("Dark Mode Toggle - System mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <rect
                        x="2"
                        y="3"
                        width="20"
                        height="14"
                        rx="2"
                        ry="2"
                    ></rect>
                    <line x1="8" y1="21" x2="16" y2="21"></line>
                    <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
            </button>
        </div>
    );
}
